// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-boneluv-jose-manuel-ramirez-diseno-web-js": () => import("/opt/build/repo/src/pages/boneluv-jose-manuel-ramirez-diseno-web.js" /* webpackChunkName: "component---src-pages-boneluv-jose-manuel-ramirez-diseno-web-js" */),
  "component---src-pages-contacto-js": () => import("/opt/build/repo/src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-faq-js": () => import("/opt/build/repo/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-planes-y-precios-diseno-web-marketing-digital-js": () => import("/opt/build/repo/src/pages/planes-y-precios-diseno-web-marketing-digital.js" /* webpackChunkName: "component---src-pages-planes-y-precios-diseno-web-marketing-digital-js" */),
  "component---src-pages-portfolio-js": () => import("/opt/build/repo/src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-servicios-diseno-web-js": () => import("/opt/build/repo/src/pages/servicios/diseno-web.js" /* webpackChunkName: "component---src-pages-servicios-diseno-web-js" */),
  "component---src-pages-servicios-diseno-web-landing-page-js": () => import("/opt/build/repo/src/pages/servicios/diseno-web/landing-page.js" /* webpackChunkName: "component---src-pages-servicios-diseno-web-landing-page-js" */),
  "component---src-pages-servicios-diseno-web-tienda-online-js": () => import("/opt/build/repo/src/pages/servicios/diseno-web/tienda-online.js" /* webpackChunkName: "component---src-pages-servicios-diseno-web-tienda-online-js" */),
  "component---src-pages-servicios-diseno-web-web-corporativa-js": () => import("/opt/build/repo/src/pages/servicios/diseno-web/web-corporativa.js" /* webpackChunkName: "component---src-pages-servicios-diseno-web-web-corporativa-js" */),
  "component---src-pages-servicios-posicionamiento-web-js": () => import("/opt/build/repo/src/pages/servicios/posicionamiento-web.js" /* webpackChunkName: "component---src-pages-servicios-posicionamiento-web-js" */),
  "component---src-pages-servicios-redes-sociales-js": () => import("/opt/build/repo/src/pages/servicios/redes-sociales.js" /* webpackChunkName: "component---src-pages-servicios-redes-sociales-js" */),
  "component---src-pages-submited-js": () => import("/opt/build/repo/src/pages/submited.js" /* webpackChunkName: "component---src-pages-submited-js" */)
}

